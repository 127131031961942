<template>
  <base-view
      @update:search-values="updateSearch"
      :search-columns="searchColumns">
    <div>
      <arquivos-remessa-data-table ref="dataTable"
                         :search-values="searchValues"/>
    </div>
  </base-view>

</template>

<script>
import ArquivosRemessaDataTable from "../../../components/shared/datatables/ArquivosRemessaDataTable";
import BaseView from "../../BaseView";
import {ApiError} from "../../../api/core";
import * as moment from 'moment';

export default {
  name: "index",
  components: {BaseView, ArquivosRemessaDataTable},
  data() {
    return {
      item: null,
      editDialogOpened: false,
      selectedItems: [],
      searchValues: {},
      searchColumns: [
        {
          group: 'Período',
          columns: [
            {
              name: 'De',
              column: 'dtInicio',
              type: 'date',
              defaultValue: moment().startOf('month').format('YYYY-MM-DD'),
            },
            {
              name: 'Até',
              column: 'dtFim',
              type: 'date',
              defaultValue: moment().endOf('month').format('YYYY-MM-DD'),
            },
          ]
        },
        {
          name: 'Nº remessa',
          column: 'numRemessa',
          type: 'number'
        },
        
      ],
    }
  },
  methods: {
    updateSearch(search) {
      this.searchValues = search;
    },
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>