<template>
  <div>

    <v-data-table :headers="headers"
                  dense
                  fixed-header
                  :options.sync="pageOptions"
                  :server-items-length="totalElements"
                  :disable-sort="true"
                  :hide-default-header="$vuetify.breakpoint.mobile"
                  :footer-props="{
                    disableItemsPerPage: true,
                    itemsPerPageOptions: [itemsPerPage],
                  }"
                  :items="elements" :loading="loading">
      <template v-slot:item.url="{item}">
        <strong>
          <a target="_blank" :href="item.url" title="Ver conteúdo">
            <v-icon>mdi-eye-outline</v-icon>
          </a>
        </strong>
      </template>
      <template v-slot:item.caminho="{item}">
        <v-btn @click="downloadRemessa(item.caminho, item.nome)" small icon>
          <v-icon>mdi-download-box</v-icon>
        </v-btn>
      </template>
     

      <template v-slot:body="{items, isSelected, select}" v-if="$vuetify.breakpoint.mobile">
        <v-card v-if="items.length === 0" class="elevation-0">
          <v-card-text class="text-center">
            <p>Não há dados disponíveis</p>
          </v-card-text>
        </v-card>
        <v-list dense class="pa-0">
          <v-list-item @click="showMenu($event, item)" v-for="item of items" :key="item.id"
                       class="list-item">
            <v-list-item-avatar>
              #{{ item.id }}
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="item.nome"/>
              <v-list-item-subtitle>
                <a :href="item.caminho" target="_blank" download="">ver arquivo</a>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>

    </v-data-table>
  </div>
</template>

<script>

import {findAllArquivosRemessa} from '../../../api/faturas';
import ResponsiveMenu from "../../app/ResponsiveMenu";
import * as moment from 'moment';
import {downloadArquivoRemessa} from "../../../api/relatorios";

/**
 * @emits click:edit
 * @emit click:remove
 */
export default {
  name: "ArquivosRemessaDataTable",
  components: {ResponsiveMenu},
  props: {
    searchValues: {
      type: Object,
    },
  },
  data() {
    return {
      selectedItem: null,
      menuOptions: [
      ],
      loading: false,
      error: null,
      pageOptions: {
        page: 1
      },
      itemsPerPage: 0,
      totalElements: 0,
      elements: [],
      headers: [
        {
          text: 'Visualizar',
          value: 'url',
          align: 'center',
          width: "5%"
        },
        {
          text: 'Download',
          value: 'caminho',
          align: 'center',
          width: "5%"
        },        
        {
          text: 'Data',
          value: 'data',
          align: 'start',
          width: "20%"
        },
        {
          value: 'nome',
          text: 'Nome',
          align: 'start',
          width: "70%"
        },
      ],
    }
  },
  computed: {
    selectedItemName() {
      return this.selectedItem?.descricao
    },
  },
  watch: {
    searchValues: {
      deep: true,
      handler() {
        this.refresh();
      }
    },
    'pageOptions.page': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.refresh(newValue);
      }
    }
  },
  methods: {
    async refresh() {
      try {
        this.error = null;
        this.loading = true;
        const page = await findAllArquivosRemessa(this.pageOptions.page, this.searchValues);

        this.itemsPerPage = page.elementsPerPage;
        this.totalElements = page.totalElements;
        this.elements = page.elements;
        this.pageOptions.page = page.currentPage;

      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    },
    async downloadRemessa(fileUrl, nome) {
      console.log(fileUrl, nome);
      let item = {
        formatoSaida: "txt",
        nome: nome,
        url: fileUrl
      };
      this.loading = true;
      await downloadArquivoRemessa(item);
      this.loading = false;
    },
  },
  mounted() {
    this.pageOptions.page = 1;
    this.refresh();
  }
}
</script>

<style scoped lang="scss">
.list-item {
  &::v-deep {
    .v-list-item__content {
      max-width: calc(100vw - 128px);
    }
  }
}
.list-item:not(:last-child) {
  border-bottom: 1px solid #dedede
}

</style>